import { Link } from '~components'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images, SocialMedia } from '~data'
import Footer from "./style"
export default function FooterSection(){
return(
    <Footer>
    <Container>
        <Footer.Box pbXL="95px">
        <Row>
            <Col xs="12" className="col-lg-4 col-md-8 col-xs-10">
            <Footer.Widgets className="footer-widgets footer-widgets--l7">
                {/* Brand Logo*/}
                <Footer.Box mb="30px">
                    <Link  to="#">
                        <img src={Images.Logo.LogoBlack} alt="logo" />
                    </Link>
                </Footer.Box>
                <Footer.Text mb="36px">
                We are a consulting agency<br className="d-none d-xl-block" /> with a main goal to transform<br className="d-none d-xl-block" /> our collaborators business/organizations.</Footer.Text>

            </Footer.Widgets>
            </Col>
            <Col xs="12" className="col-lg-8">
            <Row>
            <Col xs="12" className="col-lg-4 col-xs-6">
                    <Footer.Widgets>
                        <Footer.Title>Contact Details</Footer.Title>
                        <Footer.Address className="widgets-address">
                            <Footer.AddressItem>
                                <span>Quatre Bornes <br className="d-block" />
                                Mauritius</span>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <a href="#">+230 570 722 59 </a>
                            </Footer.AddressItem>
                            <Footer.AddressItem>
                                <a href="mailto:admin@afratex.com">admin@afratex.com</a>
                            </Footer.AddressItem>
                        </Footer.Address>
                    </Footer.Widgets>
                </Col>
                <Col xs="12" className="col-lg-4 col-xs-6">
                <Footer.Widgets>
                    <Footer.Title>Company</Footer.Title>
                    <Footer.List>
                        
                        <Footer.ListItems>
                            <a href="#">Privacy
                            Policy</a>
                        </Footer.ListItems>
                        <Footer.ListItems>
                            <a href="#">Terms &amp;
                            Conditions</a>
                        </Footer.ListItems>
                    </Footer.List>
                </Footer.Widgets>
                </Col>

                <Col xs="12" className="col-lg-4 col-xs-7">
                <Footer.Widgets>
                    <Footer.Title>Some of our products</Footer.Title>
                    <Footer.Gallery as={Row}>
                        <Col xs="4">
                            <img src={Images.footerThree.instaImg1} alt="insta-image" className="w-100" />
                        </Col>
                        <Col xs="4">
                            <img src={Images.footerThree.instaImg2} alt="insta-image" className="w-100" />
                        </Col>
                        <Col xs="4">
                            <img src={Images.footerThree.instaImg3} alt="insta-image" className="w-100" />
                        </Col>
                        <Col xs="4">
                            <img src={Images.footerThree.instaImg4} alt="insta-image" className="w-100" />
                        </Col>
                        <Col xs="4">
                            <img src={Images.footerThree.instaImg5} alt="insta-image" className="w-100" />
                        </Col>
                        <Col xs="4">
                            <img src={Images.footerThree.instaImg6} alt="insta-image" className="w-100" />
                        </Col>
                    </Footer.Gallery>
                </Footer.Widgets>
                </Col>
            </Row>
            </Col>
        </Row>
        </Footer.Box>
        <Footer.Copyright>
        <Footer.CopyrightText>© 2021 Afratex Ltd. All Rights Reserved</Footer.CopyrightText>
        <Footer.SocialShare>
            {SocialMedia.map(({ id, platform, link, fa_icon }) => {
            return (
                <Footer.SocialShareItem
                    key={id}
                >
                <a href={link}  target="_blank">
                <i className={fa_icon} />
                </a>
            </Footer.SocialShareItem>
            )
            })}
        </Footer.SocialShare>
        </Footer.Copyright>
    </Container>
    </Footer>
)
}